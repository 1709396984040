import {useState, useEffect} from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import {withTranslation} from 'react-i18next'
import dateFormat from 'dateformat'
import NumberFormat from 'react-number-format'
import CountdownTimer from '../CountdownTimer'
import BlockUi from 'react-block-ui'
// import { "" } from ',';

const LotteryGrid = (props: any) => {
    const {
        t,
        sub_title,
        title,
        next_price,
        subscribe_and_buy,
        buy_now,
        draw_no,
        draw_date,
        draw_close,
        no_lottery,
        setQuickOpen,
        setScheduleOpen,
        setSelectedLottery,
        setQuickBuyLoginOpen,
        setScheduleBuyLoginOpen
    } = props

    const axiosPrivate = useAxiosPrivate()
    const [lotteries, setLotteries] = useState()
    const [message, setMessage] = useState('')
    const [blocking, setBlocking] = useState(true)
    const [specialLottery, setSpecialLottery] = useState(['34', '45', '49'])
    const [token, setToken] = useState('')


    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()

        const getLotteries = async () => {
            try {
                setBlocking(true)
                let atkn = localStorage.getItem('atkn')
                setToken(atkn)
                const response = await axiosPrivate.post('lottery/list', {
                    signal: controller.signal,
                })
                setBlocking(false)

                isMounted && setLotteries(response.data?.lotttery)
                if (response.data?.lotttery.length == 0)
                    setMessage('No lotteries to display')
            } catch (err) {
                // console.error(err)
            }
        }

        getLotteries()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [])

    const handleQuickBuyModelOpen = (lottery: any) => {
        setSelectedLottery(lottery)
        if (token) {
            setQuickOpen(true)
        } else {
            setQuickBuyLoginOpen(true)
        }
    }

    //   const DRAW_DATE_COUNTER =  new Date('2022-06-30 16:30:00').getTime();

    const handleScheduleModelOpen = (lottery: any) => {
        setSelectedLottery(lottery)
        if (token) {
            setScheduleOpen(true)
        } else {
            setScheduleBuyLoginOpen(true)
        }
    }


    return (
        <>
            <section className="official-lotteries-section">

                <div className="container">
                    <div className="row top-wrapper">
                        <div
                            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 wrapper three"
                            data-aos="fade-up"
                        >
                            <h2>{t(title)}</h2>
                            <p>{t(sub_title)}</p>
                        </div>
                    </div>
                    <BlockUi tag="div" blocking={blocking}>
                        <div className="row bottom-wrapper">
                            <div className="row row-clr ">
                                {lotteries?.length && !blocking ? (
                                    <ul>
                                        {lotteries.map((lottery: any, i: number) => (
                                            <div
                                                key={i}
                                                data-aos={i % 2 == 0 ? 'fade-left' : 'fade-right'}
                                                className="col-xs-12 col-sm-6 col-md-6 col-lg-3 common-col  "
                                            >

                                                <div
                                                    className={lottery.lottery_id == specialLottery[0] || lottery.lottery_id == specialLottery[1] || lottery.lottery_id == specialLottery[2] ? "row row-clr inner-box special-lottery " : "row row-clr inner-box "}>

                                                    {lottery.lottery_id == specialLottery[0] || lottery.lottery_id == specialLottery[1] || lottery.lottery_id == specialLottery[2] ?
                                                        <div className="row row-clr top-heading-wrapper-special ">

                                                            <div className="ribbon-wrapper">
                                                                <div className="ribbon">SPECIAL</div>
                                                            </div>

                                                            <p>{t(lottery.name)}</p>

                                                        </div>
                                                        :
                                                        <div className="row row-clr top-heading-wrapper ">


                                                            <p>{t(lottery.name)}</p>

                                                        </div>
                                                    }
                                                    <div className="price-wrapper">
                                                        <h5>
                                                            <p>{t(next_price)}</p>
                                                            <span>LKR </span>
                                                            <NumberFormat
                                                                value={
                                                                    lottery.next_super_price
                                                                        ? lottery.next_super_price
                                                                        : lottery.winning_price
                                                                }
                                                                displayType={'text'}
                                                                // suffix="/="
                                                                thousandSeparator={true}
                                                            />
                                                        </h5>
                                                        {/* <p>{t(next_price)}</p> */}
                                                    </div>

                                                    <div className="logo-wrapper">
                                                        <img
                                                            src={`${lottery.image_file_path}`}
                                                            className="img-responsive"
                                                            alt="Image"
                                                        />
                                                    </div>

                                                    <div className="button-wrapper-wrapper ">
                                                        {lottery.status == 'Available' && (
                                                            <>
                                                                {lottery.lottery_id == specialLottery[0] || lottery.lottery_id == specialLottery[1] || lottery.lottery_id == specialLottery[2] ?
                                                                    <>
                                                                        <a
                                                                            className="button-type-2 cursor-on"
                                                                            onClick={() =>
                                                                                handleQuickBuyModelOpen(lottery)
                                                                            }
                                                                        >
                                                                            Buy Now
                                                                        </a>

                                                                        {/* <a
                                      className="button-type-3 cursor-on"
                                      onClick={() =>
                                        handleScheduleModelOpen(lottery)
                                      }
                                    >
                                      {t(schedule_and_buy)}
                                      <br />
                                      <span>{t(subscribe)} </span>
                                    </a> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <a
                                                                            className="button-type-2 cursor-on"
                                                                            onClick={() =>
                                                                                handleQuickBuyModelOpen(lottery)
                                                                            }
                                                                        >
                                                                            Buy Now
                                                                        </a>
                                                                        <a
                                                                            className="button-type-3 cursor-on"
                                                                            onClick={() =>
                                                                                handleScheduleModelOpen(lottery)
                                                                            }
                                                                        >
                                                                            Subscribe & Buy

                                                                        </a>
                                                                    </>
                                                                }

                                                            </>
                                                        )}
                                                        {/* {lottery.status == 'Sold Out' && (
                              <>
                              {lottery.lottery_id==35 }

                                <a className="button-type-11">
                                  {t('Out Of Stock')}
                                </a>
                                <a
                                  className="button-type-3 cursor-on"
                                  onClick={() =>
                                    handleScheduleModelOpen(lottery)
                                  }
                                >
                                  {t(schedule)}
                                  <br />
                                  <span>({t(subscribe)}) </span>
                                </a>
                              </>
                            )} */}{lottery.status == 'Sold Out' && (
                                                        <>
                                                            {lottery.lottery_id == 35 ?
                                                                <>
                                                                    <a className="button-type-11 mega-60"
                                                                       style={{backgroundColor: 'purple'}}>
                                                                        {t('Out of Stock')}
                                                                    </a>
                                                                    {/* <a
                                      className="button-type-3 cursor-on"
                                      onClick={() =>
                                        handleScheduleModelOpen(lottery)
                                      }
                                    >
                                      {t(schedule)}
                                      <br />
                                      <span>({t(subscribe)}) </span>
                                    </a> */}
                                                                </>
                                                                :
                                                                <>
                                                                    <a className="button-type-11">
                                                                        {t('Sold Out')}
                                                                    </a>
                                                                    {/* <a
                                      className="button-type-3 cursor-on"
                                      onClick={() =>
                                        handleScheduleModelOpen(lottery)
                                      }
                                    >
                                         Subscribe
                                    </a> */}
                                                                </>
                                                            }
                                                        </>
                                                    )}
                                                    </div>

                                                    <>
                                                        {lottery.lottery_id == specialLottery[0] || lottery.lottery_id == specialLottery[1] || lottery.lottery_id == specialLottery[2] ?

                                                            <div className="row row-clr detail-wrapper-wrapper">
                                                                <div className='detail-wrapper-wrapper-special'></div>
                                                                <div
                                                                    className="col-xs-3 col-sm-3 col-md-3 col-lg-3 content-box content-box-1">
                                                                    <p>{t(draw_no)}</p>
                                                                    <h6>{lottery.current_draw_number}</h6>
                                                                </div>
                                                                <div
                                                                    className="col-xs-3 col-sm-4 col-md-4 col-lg-4 content-box content-box-2">
                                                                    <p> {t(draw_date)} </p>
                                                                    <h6>
                                                                        {lottery.current_draw_date != undefined &&
                                                                            dateFormat(
                                                                                lottery?.current_draw_date.replace(
                                                                                    ' ',
                                                                                    'T'
                                                                                ),
                                                                                'yyyy-mm-dd'
                                                                            )}
                                                                    </h6>
                                                                </div>
                                                                <div
                                                                    className="col-xs-5 col-sm-5 col-md-5 col-lg-5 content-box content-box-3">
                                                                    <p>{t(draw_close)}</p>
                                                                    <h6 className="red">
                                                                        <div className="show-counter">
                                                                            <CountdownTimer
                                                                                targetDate={lottery.current_end_date}
                                                                            />
                                                                        </div>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="row row-clr detail-wrapper-wrapper">
                                                                <div
                                                                    className="col-xs-3 col-sm-3 col-md-3 col-lg-3 content-box content-box-1">
                                                                    <p>{t(draw_no)}</p>
                                                                    <h6>{lottery.current_draw_number}</h6>
                                                                </div>
                                                                <div
                                                                    className="col-xs-3 col-sm-4 col-md-4 col-lg-4 content-box content-box-2">
                                                                    <p> {t(draw_date)} </p>
                                                                    <h6>
                                                                        {lottery.current_draw_date != undefined &&
                                                                            dateFormat(
                                                                                lottery?.current_draw_date.replace(
                                                                                    ' ',
                                                                                    'T'
                                                                                ),
                                                                                'yyyy-mm-dd'
                                                                            )}
                                                                    </h6>
                                                                </div>
                                                                <div
                                                                    className="col-xs-5 col-sm-5 col-md-5 col-lg-5 content-box content-box-3">
                                                                    <p>{t(draw_close)}</p>
                                                                    <h6 className="red">
                                                                        <div className="show-counter">
                                                                            <CountdownTimer
                                                                                targetDate={lottery.current_end_date}
                                                                            />
                                                                        </div>
                                                                    </h6>
                                                                </div>
                                                            </div>

                                                        }
                                                    </>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>{t(no_lottery)}</p>
                                )}
                            </div>
                        </div>
                    </BlockUi>
                </div>

            </section>
        </>
    )
}

export default withTranslation()(LotteryGrid)