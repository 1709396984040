import {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import NumberFormat from 'react-number-format'
import {HashLink as Link} from 'react-router-hash-link'
import axios from '../../api/axios'
import Switch from '@mui/material/Switch'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import useAuth from '../../hooks/useAuth'
import BlockUi from 'react-block-ui'

const MERCHANT_PG_IDENTIFIER = process.env.GENIE_MERCHANT_PG_IDENTIFIER
const CURRENCY = process.env.GENIE_CURRENCY
const sPAYMENT_METHOD = process.env.GENIE_PAYMENT_METHOD
const SUCCESS_URL = process.env.GENIE_SUCCESS_URL
const ERROR_URL = process.env.GENIE_ERROR_URL
const STORE_NAME = process.env.GENIE_STORE_NAME
const GENIE_URL = process.env.GENIE_URL
const MERCHANT_NAME = process.env.MERCHANT_NAME
const MINIMUM_TOPUP_AMOUNT = process.env.MINIMUM_TOPUP_AMOUNT

const TopupForm = (props: any) => {
    const {
        t,
        open,
        handleClose,
        total,
        tickets,
        handleBuyTickets,
        subscriptionRequest,
        handlErrors,
        lotteryName,
        readOnly,
    } = props

    // console.log("total", total)
    const navigate = useHistory()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'
    const {auth} = useAuth()

  const axiosPrivate = useAxiosPrivate()
  const [amount, setAmount] = useState(MINIMUM_TOPUP_AMOUNT)
  const [order, setOrder] = useState({})
  const [token, setToken] = useState({})
  const [user, setUser] = useState({})
  const [errMsg, setErrMsg] = useState({})
  const [save, setSave] = useState(false)
  const [saveCard, setSaveCard] = useState('N')
  const [cardList, setCardList] = useState([])
  const [card, setCard] = useState()
  const [recPayment, setRecPayment] = useState([])
  const [blocking, setBlocking] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [error, setError] = useState(false)
  const [checked, setChecked] = useState(false)

    const [tokenization, setTokenization] = useState(1)

    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if (Object.keys(tickets).length > 0) {
            if (cardList.length > 0) {
                payOrderEx()
            } else {
                createOrder()
            }
        }
    }, [tickets])

    useEffect(() => {
        if (Object.keys(subscriptionRequest).length > 0) {
            if (cardList.length > 0) {
                payOrderEx()
            } else {
                createOrder()
            }
        }
    }, [subscriptionRequest])

    useEffect(() => {
        if (Object.keys(order).length > 0) {
            document.getElementById('transForm').submit()
        }
    }, [order])

    useEffect(() => {
        const userx = JSON.parse(localStorage.getItem('user'))
        const atkn = localStorage.getItem('atkn')

    setErrMsg('')
    setUser(userx)
    setToken(atkn)
    setRecPayment([])
    setCardList([])
    setSuccess(false)
    setErrorMsg('')
    // console.log(total);
    if (parseFloat(total).toFixed(2) > parseInt(MINIMUM_TOPUP_AMOUNT)) {
      console.log('total', total)
      console.log('Math.ceil(total)', Math.ceil(total))
      setAmount(Math.ceil(total))
    }

    if (parseFloat(total).toFixed(2) <= parseInt(MINIMUM_TOPUP_AMOUNT)) {
      setAmount(MINIMUM_TOPUP_AMOUNT)
    }

    const controller = new AbortController()

    const fetchCardList = async () => {
      try {
        const response = await axiosPrivate.get('genieBusinessCard/list', {
          signal: controller.signal,
        })

                const cardx = response?.data

                setCardList(cardx)
                if (cardx) {
                    setCard(cardx[0]['id'])
                }
            } catch (err) {
                if (!err?.response) {
                    setErrMsg(t('No Server Response'))
                } else if (err.response?.status === 422) {
                    setErrMsg(err.response.data?.errors[0])
                } else {
                    setErrMsg(err.response.data?.errors?.message)
                }
            }
        }

    fetchCardList()
  }, [open, total])
  const handleAmountChange = (e) => {
    const value = e.target.value
    if (/^\d*$/.test(value)) {
      setAmount(value)
    } else {
      ;<h6>Please enter an integer value.</h6>
    }
  }
  const createOrderEx = (e: any) => {
    e.preventDefault()
    payOrderEx()
  }

  const payOrderEx = async () => {
    try {
      if (
        amount == undefined ||
        parseFloat(amount).toFixed(2) < parseInt(MINIMUM_TOPUP_AMOUNT)
      ) {
        setErrorMsg(
          'Entered Topup Amount Error.The minimum topup amount is : ' +
            MINIMUM_TOPUP_AMOUNT
        )
        setError(true)
        setBlocking(false)
        return
      }

            if (card == undefined) {
                setErrorMsg(t('Please select a credit card.'))
                setError(true)
                setBlocking(false)
                return
            }

      setBlocking(true)
      const response = await axiosPrivate.post('/payby/genieBusiness/card', {
        card_id: card,
        amount: amount,
        tickets: tickets,
        subscription: subscriptionRequest,
      })
      console.log('responsed', response)

      const url = response?.data?.transaction['vendorUrl']
      window.location.href = url
      // const transaction = response?.data?.transaction
      // const ticketdata = response?.data?.ticket_data
      // const subscriptiondata = response?.data?.subscription_data

      // if (Object.keys(tickets).length > 0) {
      //     handleBuyTickets(ticketdata, transaction)
      // } else if (Object.keys(subscriptionRequest).length > 0) {
      //     handleBuyTickets(subscriptiondata, transaction)
      // } else {
      //     setSuccess(true)
      //     setRecPayment(transaction)
      // }
      setBlocking(false)
    } catch (err) {
      setBlocking(false)
      // handleBuyTickets({}, {})
      if (!err?.response) {
        handleBuyTickets({}, {})
        if (handlErrors) handlErrors('No Server Response')
        else setErrMsg(t('No Server Response'))
      } else if (err.response?.status === 422) {
        if (Array.isArray(err.response.data?.errors)) {
          if (handlErrors) handlErrors(err.response.data?.errors[0])
          else setErrMsg(err.response.data?.errors[0])
        } else if (handlErrors) handlErrors(err.response.data?.errors)
        else setErrMsg(err.response.data?.errors)
      } else {
        if (handlErrors) handlErrors(err.response.data?.errors?.message)
        else setErrMsg(err.response.data?.errors?.message)
      }
    }
  }

    const createOrderX = async (e: any) => {
        e.preventDefault()
        createOrder()
    }

  const createOrder = async () => {
    try {
      setBlocking(true)
      if (
        amount == undefined ||
        parseFloat(amount).toFixed(2) < parseInt(MINIMUM_TOPUP_AMOUNT)
      ) {
        setErrorMsg(
          'Entered Topup Amount Error.The minimum topup amount is : ' +
            MINIMUM_TOPUP_AMOUNT
        )
        setBlocking(false)
        setError(true)
        return
      }
      console.log('genie Topup ammount', amount)
      const response = await axiosPrivate.post(
        'user/transaction/addGenieBusiness/' + user.id,
        {
          payment_type: 'genie',
          // amount: parseFloat(amount).toFixed(2),
          checked: checked,
          save_card: saveCard,
          amount: amount,
          tokenization: tokenization,
          lottery_name: lotteryName,
          tickets_request:
            Object.keys(tickets).length > 0 ? JSON.stringify(tickets) : null,
          subscription_request:
            Object.keys(subscriptionRequest).length > 0
              ? JSON.stringify(subscriptionRequest)
              : null,
        }
      )

      console.log(response)
      const url = response?.data?.transaction['decodedSecretCode']['url']
      window.location.href = url

      const orderx = response?.data?.transaction
      /*     setOrder(orderx)
            setBlocking(false) */
    } catch (err) {
      setBlocking(false)
      if (!err?.response) {
        setErrMsg(t('No Server Response'))
      } else if (err.response?.status === 422) {
        if (Array.isArray(err.response.data?.errors))
          setErrMsg(err.response.data?.errors[0])
        else setErrMsg(err.response.data?.errors)
      } else {
        setErrMsg(err.response.data?.errors?.message)
      }
    }
  }

  const handleCardSaving = (e: any) => {
    const isChecked = e.target.checked
    if (isChecked) {
      setChecked(true)
      setSaveCard('Y')
    } else {
      setChecked(false)
      setSaveCard('N')
    }
    setSave(isChecked)
  }

    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                <div className="row popup-section">
                    {/* <img
                src={require('../../assets/images/popup/close-1.png')}
                onClick={handleClose}
                className="img-responsive close-click pull-right"
                alt="Image"
              /> */}
          {success && (
            <>
              <div className="row popup-section">
                {Object.keys(tickets).length == 0 && (
                  <img
                    src={require('../../assets/images/popup/close-1.png')}
                    onClick={handleClose}
                    className="img-responsive close-click pull-right"
                    alt="Image"
                  />
                )}
                <div className="confirmation-wrapper">
                  <img
                    src={require('../../assets/images/confirmation-1.png')}
                    className="img-responsive"
                    alt="Image"
                  />
                  <h4>
                    Payment Completed
                    <br />
                    <NumberFormat
                      value={recPayment.charge_total}
                      displayType={'text'}
                      prefix="LKR "
                      suffix="/="
                      thousandSeparator={true}
                    />
                  </h4>
                  <div className="row confirmation-row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <p>{recPayment.transaction_end_date}</p>
                      <h5>{t('Payment Date')}</h5>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <p>{recPayment.id}</p>
                      <h5> {t('Invoice No')}</h5>
                    </div>
                  </div>
                  <div className="row confirmation-row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <p> {recPayment.txn_id}</p>
                      <h5>{t('Transaction ID')}</h5>
                    </div>
                  </div>
                  <div className="row confirmation-row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h6>{t('Thanking you.')}</h6>
                    </div>
                  </div>
                  <Link
                    className="button-type-7 cursor-on"
                    to="/my-transactions"
                  >
                    {t('Go to My Transactions')}
                  </Link>
                </div>
              </div>
            </>
          )}

          {recPayment.length == 0 && cardList.length > 0 && (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row top-wrapper">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <h4>Topup Account</h4>
                  <p>Pay by Genie (Master/Visa/Justpay)</p>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <img
                    src={require('../../assets/images/popup/4.png')}
                    className="img-responsive"
                    alt="Image"
                  />

                  {Object.keys(tickets).length == 0 && (
                    <img
                      src={require('../../assets/images/popup/close-1.png')}
                      onClick={handleClose}
                      className="img-responsive close-click pull-right"
                      alt="Image"
                    />
                  )}
                </div>
              </div>

              <div className="row mid-wrapper">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                  <h4>{t('Amount')}</h4>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                  <input
                    type="number"
                    readOnly={readOnly}
                    name="amount"
                    id="amount"
                    min={MINIMUM_TOPUP_AMOUNT}
                    className="form-control"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                    title=""
                  />
                </div>
              </div>

              <div className="row bottom-wrapper">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                  <h4>{t('Credit Cards')}</h4>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                  <div className="row">
                    {cardList.map((card: any, i: number) => (
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <label>
                          <input
                            defaultChecked={card.is_default}
                            type="radio"
                            name="radio-1"
                            value={card.id}
                            onChange={(e) => setCard(e.target.value)}
                          />
                          <span>
                            {card.card_type == 'VISA' && (
                              <img
                                src={require('../../assets/images/visas.png')}
                                className="img-responsive"
                                alt="Image"
                              />
                            )}
                            {card.card_type == 'MASTERCARD' && (
                              <img
                                src={require('../../assets/images/mastercardd.png')}
                                className="img-responsive"
                                alt="Image"
                              />
                            )}
                            &nbsp;Ending with {card.card_postfix}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {total == 0 && (
                <div className="row bottom-wrapper">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5"></div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                    {errMsg && (
                      <p aria-live="assertive">
                        <span className="has-error">{errMsg}</span>
                      </p>
                    )}
                    <button
                      type="button"
                      className="button-type-8"
                      onClick={(e) => createOrderEx(e)}
                    >
                      {t('Topup NOW')}
                    </button>
                    {error && (
                      <Collapse in={error}>
                        <Alert severity="error" onClose={() => setError(false)}>
                          {errorMsg}
                        </Alert>
                      </Collapse>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {cardList.length == 0 && (
            <form
              id="transForm"
              action={`${GENIE_URL + '/tokenization?automation=yes'}`}
              method="post"
              acceptCharset="UTF-8"
              encType="application/x-www-form-urlencoded"
            >
              <input
                type="hidden"
                id="currency"
                name="currency"
                value={CURRENCY}
              />
              <input
                type="hidden"
                id="cardSaveCard"
                name="cardSaveCard"
                value={saveCard}
              />
              <input
                type="hidden"
                id="invoiceNumber"
                name="invoiceNumber"
                value={order.id}
              />
              <input
                type="hidden"
                id="merchantDisplayName"
                name="merchantDisplayName"
                value={MERCHANT_NAME}
              />
              <input
                type="hidden"
                id="language"
                name="language"
                value="en-Us"
              />
              <input
                type="hidden"
                id="merchantPgIdentifier"
                name="merchantPgIdentifier"
                value={MERCHANT_PG_IDENTIFIER}
              />
              <input
                type="hidden"
                id="chargeTotal"
                name="chargeTotal"
                value={order.amount}
              />

              <input
                type="hidden"
                id="contextPath"
                name="contextPath"
                value="complete"
              />

              <input
                type="hidden"
                id="storeName"
                name="storeName"
                value={STORE_NAME}
              />
              <input
                type="hidden"
                id="transactionDateTime"
                name="transactionDateTime"
                value={order.created_at}
              />

              <input
                type="hidden"
                id="token"
                name="token"
                value={order.secret_code}
              />
              <input
                type="hidden"
                id="reference"
                name="reference"
                value={order.id}
              />

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {Object.keys(tickets).length == 0 && (
                  <img
                    src={require('../../assets/images/popup/close-1.png')}
                    onClick={handleClose}
                    className="img-responsive close-click pull-right"
                    alt="Image"
                  />
                )}
                <div className="row top-wrapper">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <h4>Topup Account</h4>
                    <p>Pay by Genie (Master/Visa/Justpay)</p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <img
                      src={require('../../assets/images/popup/4.png')}
                      className="img-responsive"
                      alt="Image"
                    />
                    {/* {Object.keys(tickets).length == 0 && (
                      <img
                        src={require('../../assets/images/popup/close-1.png')}
                        onClick={handleClose}
                        className="img-responsive close-click pull-right" style={{marginBottom:"73px"}}
                        alt="Image"
                      />
                    )} */}
                  </div>
                </div>

                <div className="row mid-wrapper">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                    <h4>{t('Amount')}</h4>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                    {/* {console.log("target amount", amount)} */}
                    <input
                      type="number"
                      readOnly={readOnly}
                      name="amount"
                      id="amount"
                      min={MINIMUM_TOPUP_AMOUNT}
                      className="form-control"
                      value={amount}
                      onChange={handleAmountChange}
                      required
                      pattern=""
                      title=""
                    />
                  </div>
                </div>
                <div className="row lucky_number_wrapper">
                  <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 content-wrapper">
                    <div className="row">
                      <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                        <h4>{t('Save Credit Card')}</h4>
                      </div>
                      <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div
                          className="pull-right"
                          style={{ marginTop: '20px' }}
                        >
                          <Switch
                            checked={checked}
                            onChange={(e) => handleCardSaving(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {save && (
                  <div className="row bottom-wrapper">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                      <h4>{t('Make this as a Recurring payment?')}</h4>
                      <p>
                        {t(
                          'This option will topup the given amount automaticaly to your account'
                        )}
                      </p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                      <div className="row">
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                          <label>
                            <input
                              checked={tokenization == 1}
                              type="radio"
                              name="radio-1"
                              onClick={() => setTokenization(1)}
                            />
                            <span>{t('Yes')}</span>
                          </label>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                          <label>
                            <input
                              checked={tokenization == 0}
                              type="radio"
                              name="radio-1"
                              onClick={() => setTokenization(0)}
                            />
                            <span>{t('No')}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {total == 0 && (
                  <div className="row bottom-wrapper">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5"></div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                      <button
                        type="button"
                        className="button-type-8"
                        onClick={(e) => createOrderX(e)}
                      >
                        {t('Topup NOW')}
                      </button>
                      {error && (
                        <Collapse in={error}>
                          <Alert
                            severity="error"
                            onClose={() => setError(false)}
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </BlockUi>
    </>
  )
}

export default withTranslation()(TopupForm)
