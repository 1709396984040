import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { withTranslation } from 'react-i18next'
import BlockUi from 'react-block-ui'
import CountdownTimer from '../CountdownTimer'
import dateFormat from 'dateformat'
import NumberFormat from 'react-number-format'
import QuickBuyForm from '../QuickBuyForm';
import ScheduleForm from '../ScheduleForm';
// import * as config from 'config';

const lotteryList =
    {
        "lottery": {
            "MT": 35,
            "mm": 34,
            'mps': 44,
            "mp": 4,
            "ms": 2,
            "gs": 1,
            "dn": 7,
            "lk7": 37,
            "hd": 36,
            "as": 43,
            "gss": 51
        }
    }
const LotteryBlogSection = (props: any) => {
    const {
        t, setQuickOpen,
        setScheduleOpen,
        setSelectedLottery,
        setQuickBuyLoginOpen,
        setScheduleBuyLoginOpen,
        subscribe_and_buy,
        buy_now
    } = props;

    const location = useLocation();
    const axiosPrivate = useAxiosPrivate()
    const queryParams = new URLSearchParams(location.search);
    const lotterySname = queryParams.get('tk');
    const [blocking, setBlocking] = useState(true)
    const [message, setMessage] = useState('')
    const [lotteries, setLotteries] = useState()
    const [specialLottery, setSpecialLottery] = useState(['25', '27', '51'])
    const [token, setToken] = useState('')

    useEffect(() => {
        const lotteryId = lotteryList?.lottery[lotterySname]
        let isMounted = true
        const controller = new AbortController()
        const getLotteries = async () => {
            try {
                setBlocking(true)
                let atkn = localStorage.getItem('atkn')
                setToken(atkn)
                const response = await axiosPrivate.get('lottery_details/' + lotteryId, {
                    signal: controller.signal,
                })
                setBlocking(false)
                isMounted && setLotteries(response.data?.lotttery)
                console.log(response.data)
                if (response.data?.lotttery.length == 0)
                    setMessage('No lotteries to display')
            } catch (err) {
                console.error(err)
            }
        }
        getLotteries()
        return () => {
            isMounted = false
            controller.abort()
        }

    }, [lotterySname])
    const handleScheduleModelOpen = (lottery: any) => {
        if (token) {
            setScheduleOpen(true)
            setSelectedLottery(lottery)
        } else {
            setScheduleBuyLoginOpen(true)
        }
    }

    const handleQuickBuyModelOpen = (lottery: any) => {
        if (token) {
            setQuickOpen(true)
            setSelectedLottery(lottery)
        } else {
            setQuickBuyLoginOpen(true)
        }
    }


    return (
        <>
            <section className="lotteries-detail-banner-section">
                <BlockUi tag="div" blocking={blocking}>
                    {lotteries?.length && !blocking ? (
                            <div className="container">
                                {lotteries.map((lottery: any, i: number) => (
                                    <>
                                        <h2>
                                            {lottery.name}
                                        </h2>
                                        <div className="row row-clr inner-row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-wrapper">
                                                <div className="row">

                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <img src={`${lottery.image_file_path}`} className="img-responsive"
                                                             alt="Image"/>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <div className="row">
                                                            <div
                                                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-box content-box-1">
                                                                <p>
                                                                    Draw No : {lottery.current_draw_number}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-box content-box-2">
                                                                <p> Draw Date :{lottery.current_draw_date != undefined &&
                                                                    dateFormat(
                                                                        lottery?.current_draw_date.replace(
                                                                            ' ',
                                                                            'T'
                                                                        ),
                                                                        'yyyy-mm-dd'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-box content-box-3">
                                                                <p>Ticket Price : Rs. {lottery.ticket_price}/=</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-box content-box-3">
                                                                <p>Draw Close in :
                                                                    <h6 className="red">
                                                                        <div className="show-counter">
                                                                            <CountdownTimer
                                                                                targetDate={lottery.current_end_date}
                                                                            />
                                                                        </div>
                                                                    </h6>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 right-content">
                                                        <h4>
                                                            <NumberFormat
                                                                value={
                                                                    lottery.next_super_price
                                                                        ? lottery.next_super_price
                                                                        : lottery.winning_price
                                                                }
                                                                displayType={'text'}
                                                                prefix="LKR "
                                                                suffix="/="
                                                                align="right"
                                                                thousandSeparator={true}
                                                            />
                                                        </h4>
                                                        <h5 align="right">
                                                            Your Next Super Price
                                                        </h5>

                                                        <div className="button-wrapper-wrapper ">


                                                            {lottery.status == 'Available' && (
                                                                <>
                                                                    {lottery.lottery_id == specialLottery[0] || lottery.lottery_id == specialLottery[1] || lottery.lottery_id == specialLottery[2] ?
                                                                        <>
                                                                            <a
                                                                                className="button-type-2 cursor-on"
                                                                                onClick={() =>
                                                                                    handleQuickBuyModelOpen(lottery)
                                                                                }
                                                                            >
                                                                                {t(buy_now)}
                                                                            </a>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <a
                                                                                className="button-type-2 cursor-on"
                                                                                onClick={() =>
                                                                                    handleQuickBuyModelOpen(lottery)
                                                                                }
                                                                            >
                                                                                {t(buy_now)}
                                                                            </a>
                                                                            <a
                                                                                className="button-type-3 cursor-on"
                                                                                onClick={() =>
                                                                                    handleScheduleModelOpen(lottery)
                                                                                }
                                                                            >
                                                                                {t(subscribe_and_buy)}
                                                                            </a>
                                                                        </>
                                                                    }

                                                                </>
                                                            )}
                                                            {lottery.status == 'Sold Out' && (
                                                                <>
                                                                    <a className="button-type-11">
                                                                        Sold Out
                                                                    </a>
                                                                    <a
                                                                        className="button-type-3 cursor-on"
                                                                        onClick={() =>
                                                                            handleScheduleModelOpen(lottery)
                                                                        }
                                                                    >
                                                                        {t(subscribe_and_buy)}
                                                                    </a>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                                <p align="center">
                                                    Sunday | Monday | Tuesday | Thursday | Friday | Saturday
                                                </p>
                                            </div>
                                            <div className="overlay">
                                            </div>
                                        </div>
                                    </>
                                ))
                                }
                            </div>
                        ) :
                        (
                            <p>No Lottery find</p>
                        )}
                </BlockUi>
            </section>
        </>
    )
}

export default withTranslation()(LotteryBlogSection)