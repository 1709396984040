import logo from '../../assets/images/logo-nav.png'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Footer = ({ t }: any) => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row top-row">
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
              <h4>www.811.lk</h4>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-6">
              <ul className="clearfix">
                <li>
                  <Link to="/lotteries">{t('Lotteries')}</Link>
                </li>
                <li>
                  <Link to="/results">{t('Results')}</Link>
                </li>
                <li>
                  <Link to="/about">{t('About Us')}</Link>
                </li>

                <li>
                  <Link to="/contact">{t('Contact Us')}</Link>
                </li>
                <li>
                  <Link to="/faq">{t("FAQ's")}</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 social-media-wrapper">
              <ul className="clearfix">
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC_DjcRoxlrL44nBbeeSYnkw"
                    target="_blank"
                  >
                    <img
                      src={
                        'https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/Social_media/y.png'
                      }
                      className="img-responsive"
                      alt="Image"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/MobileLotteryNLB811"
                    target="_blank"
                  >
                    <img
                      src={
                        'https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/Social_media/f.png'
                      }
                      className="img-responsive"
                      alt="Image"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://mobile.twitter.com/NLB_811_Lottery"
                    target="_blank"
                  >
                    <img
                      src={
                        'https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/Social_media/t.png'
                      }
                      className="img-responsive"
                      alt="Image"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/nlb_811_mobile_lottery?igshid=YmMyMTA2M2Y="
                    target="_blank"
                  >
                    <img
                      src={
                        'https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/Social_media/link.png'
                      }
                      className="img-responsive"
                      alt="Image"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row bottom-row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
              <ul>
                <li>
                  <img
                    src={require('../../assets/images/footer-logo.png')}
                    className="img-responsive"
                    alt="Image"
                  />
                </li>
                <li>
                  <img
                    src={require('../../assets/images/footer-logo-2.png')}
                    className="img-responsive"
                    alt="Image"
                  />
                </li>
                <li>
                  <img
                    src={require('../../assets/images/footer-logo-3.png')}
                    className="img-responsive"
                    alt="Image"
                  />
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 contact-detail-wrapper">
              <div className="row row-clr">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 left">
                  <p>Email</p>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-8 right">
                  <a href="mailto:info@ccwplottery.com">info@ccwplottery.com</a>
                </div>
              </div>

              <div className="row row-clr">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 left">
                  <p>Hotline</p>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-8 right">
                  <a href="tel:+(+94)762611811">(+94) 76 2611 811</a>
                </div>
              </div>

              <div className="row row-clr">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 left">
                  <p>Whatsapp</p>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-8 right">
                  <a href="https://wa.me/94776161811">(+94) 77 6161 811</a>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-clr copyrights-wrapper">
            <ul>
              <li>
                <Link to="/terms">{t('Terms & Conditions')}</Link>
              </li>
            </ul>
            <p>© {new Date().getFullYear()} 811.lk All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default withTranslation()(Footer)
