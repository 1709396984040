const routes = [
    {
      path: ["/", "/home"],
      exact: true,
      component: "Home",
      auth:false
    },
    {
      path: ["/lotteries"],
      exact: true,
      component: "Lotteries",
      auth:false
    },
    {
        path: ["/lottery/buy/pay"],
        exact: true,
        component: "IPayLotteryBuy",
        auth: false
    },
    {
        path: ["/results"],
        exact: true,
        component: "Results",
        auth: false
    },
    {
      path: ["/winning"],
      exact: true,
      component: "Winning",
      auth:false
    },
    {
      path: ["/faq"],
      exact: true,
      component: "Faq",
      auth:false
    },
    {
      path: ["/terms"],
      exact: true,
      component: "Terms",
      auth:false
    },
    {
      path: ["/about"],
      exact: true,
      component: "About",
      auth:false
    },
    {
      path: ["/contact"],
      exact: true,
      component: "Contact",
      auth:false
    },
    {
      path: ["/blog"],
      exact: true,
      component: "Blog",
      auth:false
    },
    {
      path: ["/cart"],
      exact: true,
      component: "Cart",
      auth:false
    },
    {
      path: ["/my-tickets"],
      exact: true,
      component: "MyTickets",
      auth:true
    },
    {
      path: ["/my-points"],
      exact: true,
      component: "MyCredits",
      auth:true
    },
    {
      path: ["/my-subscriptions"],
      exact: true,
      component: "MySubscriptions",
      auth:true
    },{
      path: ["/payment-type"],
      exact: true,
      component: "PaymentType",
      auth:true
    },
    {
      path: ["/my-profile"],
      exact: true,
      component: "MyProfile",
      auth:true
    },
    {
      path: ["/my-transactions"],
      exact: true,
      component: "MyTransactions",
      auth:true
    },
    {
      path: ["/ln"],
      exact: true,
      component: "FocusTicket",
      auth:true
    },
    {
      path: ["/add-mysubscription"],
      exact: true,
      component:"AddMySubscription",
      auth:true
    },
    {
      path: ["/payment/success/:hash"],
      exact: true,
      component: "PaymentSuccess",
      auth:true
    },
    {
      path: ["/payment/error/:error"],
      exact: true,
      component: "PaymentError",
      auth:true
    },
    {
      path: ["/card/verify/:hash"],
      exact: true,
      component: "VerifyCard",
      auth:true
    },
    {
      path: ["/card/verify/success/:hash"],
      exact: true,
      component: "VerifySuccess",
      auth:true
    }

  ];

  export default routes;