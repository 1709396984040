import {useEffect} from 'react'
import InnerBanner from '../../components/InnerBanner'
import LotteryGrid from '../../components/LotteryGrid'
import ReactGA from 'react-ga'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import SnowStorm from 'react-snowstorm';
import QuickBuyForm from "../../components/QuickBuyForm";
import ScheduleForm from "../../components/ScheduleForm";
import {withTranslation} from "react-i18next";

const WHATSAPP_PHONE_NUMBER = process.env.WHATSAPP_PHONE_NUMBER

const Lotteries = (props: any) => {
    const {
        setQuickOpen,
        quickOpen,
        setScheduleOpen,
        scheduleOpen,
        setSelectedLottery,
        selectedLottery,
        quickBuyLoginOpen,
        scheduleBuyLoginOpen,
        setQuickBuyLoginOpen,
        setScheduleBuyLoginOpen,
        isLoggedOut,
        loginOpen
    } = props
    /*  useEffect(() => {
       ReactGA.pageview(window.location.pathname)
     }, [])
     const bannerURL =
       'https://ccwp-lottery-files.s3.us-east-2.amazonaws.com/web-cdn/images/lottery/inner-banner_v1.png'
    */

    useEffect(() => {
        if (isLoggedOut === true) {
            setQuickOpen(false);
            setScheduleOpen(false);
            setQuickBuyLoginOpen(false);
            setScheduleBuyLoginOpen(false);
        }
    }, [isLoggedOut]);

    const handleQuickBuyModelClose = () => {
        setQuickOpen(false)
        setQuickBuyLoginOpen(false)
    }

    const handleScheduleModelClose = () => {
        setScheduleOpen(false)
        setScheduleBuyLoginOpen(false)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        if (quickOpen || scheduleOpen) {
            scrollToTop();
        }
    }, [quickOpen, scheduleOpen]);

    useEffect(() => {
        if (isLoggedOut === true) {
            setQuickOpen(false);
            setScheduleOpen(false);
            setQuickBuyLoginOpen(false);
            setScheduleBuyLoginOpen(false);
        }
    }, [isLoggedOut]);


    return (
        <>

            {/*  <InnerBanner
        banner={bannerURL}
        // title="Lotteries"
      /> */}
            {!quickOpen && !scheduleOpen && !quickBuyLoginOpen && !scheduleBuyLoginOpen && !loginOpen &&
                <LotteryGrid
                    title="Lotteries Header"
                    sub_title="Lotteries Sub Header"
                    next_price="Next Super Price"
                    sold_out="Sold Out"
                    subscribe="Subscribe"
                    schedule="Schedule"
                    subscribe_and_buy="Subscribe & Buy"
                    buy_now="Buy Now"
                    draw_no="Draw No"
                    draw_date="Draw Date"
                    draw_close="Draw Close"
                    setQuickOpen={setQuickOpen}
                    quickOpen={quickOpen}
                    setScheduleOpen={setScheduleOpen}
                    scheduleOpen={scheduleOpen}
                    setSelectedLottery={setSelectedLottery}
                    setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                    setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                />
            }

            <>
                <QuickBuyForm
                    handleClose={handleQuickBuyModelClose}
                    quickOpen={quickOpen}
                    setQuickOpen={setQuickOpen}
                    quickBuyLoginOpen={quickBuyLoginOpen}
                    lottery={selectedLottery}
                    isLoggedOut={isLoggedOut}
                />
                <ScheduleForm
                    handleClose={handleScheduleModelClose}
                    scheduleOpen={scheduleOpen}
                    setScheduleOpen={setScheduleOpen}
                    scheduleBuyLoginOpen={scheduleBuyLoginOpen}
                    lottery={selectedLottery}
                    isLoggedOut={isLoggedOut}
                />
            </>

            <MessengerCustomerChat
                pageId="106803611863323"
                appId="3229410003991004"
            />
            <WhatsAppWidget
                phoneNo={WHATSAPP_PHONE_NUMBER}
                position="right"
                widgetWidth="300px"
                widgetWidthMobile="260px"
                autoOpen={false}
                // autoOpenTimer={5000}
                messageBox={true}
                // messageBoxTxt="Hi Team, is there any related service available ?"
                iconSize="44"
                iconColor="white"
                iconBgColor="green"//rgb(7, 94, 84)
                // headerIcon="https://proficientdesigners.in/wp-content/themes/pd/img/logo-new.png"
                headerIconColor="blue"
                // headerTxtColor="black"
                // headerBgColor="tomato"
                // headerTitle="John Doe"
                headerCaption="Online"
                // bodyBgColor="#bbb"
                chatPersonName="811NLB"
                // chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
                // footerBgColor="#999"
                btnBgColor="Green"
                btnTxtColor="white"
                btnTxt="Start Chat"
                // sendButton="Start"
            />
        </>
    )
}

export default withTranslation()(Lotteries);
