import {useState, useEffect} from 'react'

import logo from '../../assets/images/logo new 2.png'
import {withTranslation} from 'react-i18next'
import {Dropdown} from 'react-bootstrap'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import {useHistory, useLocation} from 'react-router-dom'
import {HashLink as Link} from 'react-router-hash-link'
import LoginFrom from '../LoginForm'
import useAuth from '../../hooks/useAuth'
import {useCart} from '../../context/CartProvider'

import ReactRoundedImage from 'react-rounded-image'
// import SnowStorm from 'react-snowstorm';

const Header = ({
                    t, setQuickOpen,
                    setScheduleOpen,
                    setIsLoggedOut,
                    setQuickBuyLoginOpen,
                    setScheduleBuyLoginOpen,
                    setLoginOpen,
                }: any) => {
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const navigate = useHistory()
    const [logged, setLogged] = useState(false)
    const [user, setUser] = useState({})
    const [logout, setLogout] = useState(false)
    const [toggle, setToggle] = useState(false)
    const {auth, setAuth} = useAuth()
    const [avatar_path, setAvatar] = useState('')
    const location = useLocation()
    const axiosPrivate = useAxiosPrivate()
    const {cart} = useCart()


    const totalItems = cart.reduce((acc: any, item: { quantity: any }) => acc + item.quantity, 0);


    useEffect(() => {
        if (localStorage.getItem('atkn') !== 'undefined') {
            const user = localStorage.getItem('user')
            const permissions = localStorage.getItem('permissions')
            const role = localStorage.getItem('role')
            const mobiles = localStorage.getItem('mobiles')
            const atkn = localStorage.getItem('atkn')
            if (atkn && user) {
                if (Object.keys(auth).length == 0)
                    setAuth({user, permissions, atkn, role, mobiles})
                setLogged(true)
                const userDetails = JSON.parse(user)

                if (userDetails.avatar_path == null || userDetails.avatar_path == '') {
                    setAvatar(require('../../assets/images/member-avatar.png'))
                } else {
                    setAvatar(userDetails.avatar_path)
                }

                setUser(JSON.parse(user))
            }
        }
    }, [auth])

    useEffect(async () => {
        const response = await axiosPrivate.get('web/version')
        console.log(response?.data?.version_no)
        const currentVersion = localStorage.getItem('version')
        if (currentVersion == null) {
            console.log('current')
            localStorage.setItem('version', response?.data?.version_no)
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name)
                })
            })
            window.location.reload(true)
        } else {
            if (currentVersion == response?.data?.version_no) {
                console.log('same')
            } else {
                console.log('Not Same')
                localStorage.setItem('version', response?.data?.version_no)
                caches.keys().then((names) => {
                    names.forEach((name) => {
                        caches.delete(name)
                    })
                })
                window.location.reload(true)
            }
        }
    })

    useEffect(() => {
        if (logout) {
            localStorage.clear()
            setAuth({})
            setLogged(false)
            setUser({})
            navigate.push('/')
        }
    }, [logout])

    const handleLogOut = () => {
        setLogout(true)
        localStorage.clear()
        setAuth({})
        setLogged(false)
        setIsLoggedOut(true)
        setQuickOpen(false);
        setScheduleOpen(false);
        setQuickBuyLoginOpen(false);
        setScheduleBuyLoginOpen(false);
        setUser({})
    }
    const handleLoginModelOpen = (title: any) => {
        setOpen(true)
        setTitle(title)
        setQuickOpen(false);
        setScheduleOpen(false);
        setQuickBuyLoginOpen(false);
        setScheduleBuyLoginOpen(false);
        setLoginOpen(true)
        // handleToggle()
    }
    const handleLoginModelClose = () => {
        setOpen(false)
        setLoginOpen(false)
    }
    const handleToggle = () => {
        console.log('toggle', toggle)
        setOpen(false)
        setLoginOpen(false)
        setQuickOpen(false)
        setScheduleOpen(false)
        setToggle(!toggle)
    }

    const clickOnLogo = () => {
        console.log('toggle', toggle)
        setOpen(false)
        setLoginOpen(false)
        setQuickOpen(false)
        setScheduleOpen(false)
    }


    const WHATSAPP_PHONE_NUMBER = process.env.WHATSAPP_PHONE_NUMBER
    return (
        <>
            <section className="header-section navbar-fixed-top">
                <div className="container">
                    <nav className="navbar" role="navigation">
                        <div className="navbar-header">
                            <button
                                type="button"
                                className="navbar-toggle"
                                data-toggle="collapse"
                                onClick={() => handleToggle()}
                                data-target=".navbar-ex1-collapse"
                            >
                                <span className="sr-only">{t('Toggle navigation')}</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <Link
                                to="/cart"
                                className="cart-link cart-on-mobile"
                            >
                                <span className="fa-stack fa-2x has-badge"
                                      data-count={totalItems}>
                                  <i className="fa fa-circle fa-stack-1x fa-inverse"></i>
                                  <i className="fa fa-shopping-cart fa-stack-1x red-cart"></i>
                                </span>
                            </Link>
                            <Link className="navbar-brand" to="/">
                                <img src={logo} className="img-responsive" alt="Image"
                                     onClick={() => clickOnLogo()}/>
                            </Link>
                        </div>

                        <div
                            className={
                                toggle
                                    ? 'collapse navbar-collapse navbar-ex1-collapse in'
                                    : 'collapse navbar-collapse navbar-ex1-collapse'
                            }
                        >
                            <div className="row row-clr navbar-right">
                                <div className="row row-clr top-row">
                                    <ul className="clearfix">
                                        <li>&nbsp;</li>
                                        <li>&nbsp;</li>
                                    </ul>
                                </div>
                                <div className="row bottom-row">
                                    <ul className="nav navbar-nav ">
                                        <li>
                                            <Link
                                                onClick={() => handleToggle()}
                                                className={location.pathname == '/' ? 'active' : ''}
                                                to="/"
                                            >
                                                {t('Home')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => handleToggle()}
                                                className={
                                                    location.pathname == '/lotteries' ? 'active' : ''
                                                }
                                                to="/lotteries"
                                            >
                                                {t('Lotteries')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => handleToggle()}
                                                className={
                                                    location.pathname == '/results' ? 'active' : ''
                                                }
                                                to="/results"
                                            >
                                                {t('Results')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => handleToggle()}
                                                className={
                                                    location.pathname == '/winning' ? 'active' : ''
                                                }
                                                to="/winning"
                                            >
                                                Winners
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => handleToggle()}
                                                className={
                                                    location.pathname == '/about' ? 'active' : ''
                                                }
                                                to="/about"
                                            >
                                                {t('About Us')}
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                onClick={() => handleToggle()}
                                                className={
                                                    location.pathname == '/contact' ? 'active' : ''
                                                }
                                                to="/contact"
                                            >
                                                {t('Contact Us')}
                                            </Link>
                                        </li>
                                        {!logged && (
                                            <>
                                                <li className="nav-button register-button hidden-xs">
                                                    <a onClick={() => handleLoginModelOpen('Register')}>
                                                        {t('Register')}
                                                    </a>
                                                </li>
                                                <li className="nav-button hidden-xs">
                                                    <a onClick={() => handleLoginModelOpen('Login')}>
                                                        {t('Login')}
                                                    </a>
                                                </li>
                                            </>
                                        )}
                                        {logged && (
                                            <>
                                                <li className="detail-list hidden-sm hidden-xs">
                                                    <p>
                                                        {user.fname} <br/>
                                                        {user.dialcode + user.telephone}
                                                    </p>
                                                </li>
                                                <li className="dropdown account-detail hidden-sm hidden-xs">
                                                    <Dropdown className="account-detail hidden-sm hidden-xs">
                                                        <Dropdown.Toggle
                                                            as="a"
                                                            href="#"
                                                            className="dropdown-toggle"
                                                            data-toggle="dropdown"
                                                        >
                                                            <ReactRoundedImage
                                                                image={`${avatar_path}`}
                                                                roundedSize="0"
                                                                imageWidth="40"
                                                                imageHeight="40"
                                                            />
                                                            <b className="caret visible-xs"></b>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu drop="start" as="ul">
                                                            <li>
                                                                <Dropdown.Item
                                                                    as={Link}
                                                                    className={
                                                                        location.pathname == '/my-tickets'
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                    to="/my-tickets"
                                                                >
                                                                    My Tickets
                                                                </Dropdown.Item>
                                                            </li>
                                                            <li>
                                                                <Dropdown.Item
                                                                    as={Link}
                                                                    className={
                                                                        location.pathname == '/my-subscriptions'
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                    to="/my-subscriptions"
                                                                >
                                                                    My Subscriptions
                                                                </Dropdown.Item>
                                                            </li>
                                                            <li>
                                                                <Dropdown.Item
                                                                    as={Link}
                                                                    className={
                                                                        location.pathname == '/my-points'
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                    to="/my-points"
                                                                >
                                                                    My Points
                                                                </Dropdown.Item>
                                                            </li>
                                                            <li>
                                                                <Dropdown.Item
                                                                    as={Link}
                                                                    className={
                                                                        location.pathname == '/my-transactions'
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                    to="/my-transactions"
                                                                >
                                                                    Transactions
                                                                </Dropdown.Item>
                                                            </li>
                                                            <li>
                                                                <Dropdown.Item
                                                                    as={Link}
                                                                    className={
                                                                        location.pathname == '/payment-type'
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                    to="/payment-type"
                                                                >
                                                                    Payment Options
                                                                </Dropdown.Item>
                                                            </li>
                                                            <li>
                                                                <Dropdown.Item
                                                                    as={Link}
                                                                    className={
                                                                        location.pathname == '/my-profile'
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                    to="/my-profile"
                                                                >
                                                                    Profile
                                                                </Dropdown.Item>
                                                            </li>
                                                            <li>
                                                                <Dropdown.Item
                                                                    onClick={() => handleLogOut('Login')}
                                                                >
                                                                    Logout
                                                                </Dropdown.Item>
                                                            </li>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </li>
                                            </>
                                        )}
                                        <li>
                                            <Link
                                                onClick={() => handleToggle()}
                                                to="/cart"
                                                className="cart-link"
                                            >
                                                        <span className="fa-stack fa-2x has-badge"
                                                              data-count={totalItems}>
                                                          <i className="fa fa-circle fa-stack-1x fa-inverse"></i>
                                                          <i className="fa fa-shopping-cart fa-stack-1x red-cart"></i>
                                                        </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row row-clr visible-xs mobile-menu">
                            <div className="row row-clr navbar-right">
                                <div className="row bottom-row">
                                    {!logged && (
                                        <ul className="nav navbar-nav ">
                                            <li className="nav-button">
                                                <a onClick={() => handleLoginModelOpen('Register')}>
                                                    {t('Register')}
                                                </a>
                                            </li>
                                            <li className="nav-button">
                                                <a onClick={() => handleLoginModelOpen('Login')}>
                                                    {t('Login')}
                                                </a>
                                            </li>
                                        </ul>
                                    )}
                                    {logged && (
                                        <ul className="nav navbar-nav ">
                                            <li className="nav-button">
                                                <Link to="/my-profile">{t('Profile')}</Link>
                                            </li>
                                            <li className="nav-button">
                                                <a onClick={() => handleLogOut('Login')}>
                                                    {t('Logout')}
                                                </a>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>
            <LoginFrom
                handleClose={handleLoginModelClose}
                open={open}
                title={title}
            />
        </>
    )
}

export default withTranslation()(Header)
