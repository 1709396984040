import { withTranslation } from "react-i18next";

import LogoSlickSlider from "react-slick";
import { HashLink as Link } from 'react-router-hash-link'
// import { animateScroll as scroll } from 'react-scroll';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useHistory, useLocation } from 'react-router-dom'
import CollectSpecial from '../../components/CollectSpecial'
const LogoSlider = ({ t }: any) => {
   const navigate = useHistory()
   const location = useLocation()
   // const [showComponentA, setShowComponentA] = useState(true);

   const from = location.state?.from?.pathname || '/'
   var settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 7,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 2000,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 6,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
            },
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },

      ],
   };
   const handleScroll = () => {

      navigate.push(from)
      // scroll.scrollTo(1450);
      const isMobile = window.innerWidth <= 767;
      const isTablet = window.innerWidth > 767 && window.innerWidth <= 1024;

      navigate.push(from);

      if (isMobile) {
         scroll.scrollTo(3500);
      } else if (isTablet) {
         scroll.scrollTo(1900);
      } else {
         scroll.scrollTo(1250);
      }
   }
   return (
      <>

         <section className="logo-slider-section" data-aos="fade-up">
            <div className="container">
               

               <LogoSlickSlider className="logo-slider"  {...settings}>

               {/* <Link to="/ln?tk=mss">
                     <div className="inner-wrapper">
                        <div className="logo-box" style={{border:"2px solid gold"}} >
                        <img src="https://ccwpitbucket.s3.us-east-2.amazonaws.com/lottery_files/27_1670909053.png" style={{ height: '50px' }}  className="img-responsive" alt="Image" />
                        </div>
                     </div>
                  </Link> */}
                  
                  <Link to="/ln?tk=mp">
                     <div className="inner-wrapper">
                        <div className="logo-box" >
                           <img src={require('../../assets/images/home/logo-slider/5.png')} className="img-responsive" alt="Image" />
                        </div>
                     </div>
                  </Link>
                  <Link to="/ln?tk=gs">
                     <div className="inner-wrapper">
                        <div className="logo-box">
                           <img src={require('../../assets/images/home/logo-slider/Govisetha.png')} style={{ height: '50px' }} className="img-responsive" alt="Image" />
                        </div>
                     </div>
                  </Link>
                  <Link to="/ln?tk=ms">
                     <div className="inner-wrapper">
                        <div className="logo-box">
                           <img src={require('../../assets/images/home/logo-slider/7.png')} className="img-responsive" alt="Image" />
                        </div>
                     </div>
                  </Link>
                  <Link to="/ln?tk=hd">
                     <div className="inner-wrapper">
                        <div className="logo-box">
                           <img src={require('../../assets/images/home/logo-slider/8.png')} className="img-responsive" alt="Image" />
                        </div>
                     </div>
                  </Link>
                  <Link to="/ln?tk=dn">
                     <div className="inner-wrapper">
                        <div className="logo-box">
                           <img src={require('../../assets/images/home/logo-slider/3.png')} className="img-responsive" alt="Image" />
                        </div>
                     </div>
                  </Link> <Link to="/ln?tk=as">
                     <div className="inner-wrapper">
                        <div className="logo-box">
                           <img src={require('../../assets/images/home/logo-slider/10.png')} style={{ height: '48px' }} className="img-responsive" alt="Image" />
                        </div>
                     </div>
                  </Link>
                  <Link to="/ln?tk=lk7">
                     <div className="inner-wrapper">
                        <div className="logo-box">
                           <img style={{ height: '50px' }} src={require('../../assets/images/home/logo-slider/11.png')} className="img-responsive" alt="Image" />
                           {/* <img src={require('../../assets/images/home/logo-slider/4.png')} className="img-responsive" alt="Image" /> */}
                        </div>
                     </div>
                  </Link>
                 
                 
                 
              
                 

                 

               
               

               </LogoSlickSlider>

            </div>


            {/* <div className="boxbdr">
               <div className="digital" >
                  <div className="boxes " >
                     <div className="box box-one" >
                        <div className="animation">
                           <Link to="/ln?tk=mm">
                              <div className="inner-wrapper">
                                 <div className="logo-box" >

                                    <img className="img-fluid" style={{ height: '75px', marginTop: "10px" }} src={require('../../assets/images/home/logo-slider/click_here.png')} className="img-responsive" alt="Image" />
                                 </div>
                              </div>
                           </Link> */}

            {/* <span>To Win Your Mega Millions!!  Be Rich 🤩</span> */}
            {/* <Link to="/ln?tk=mm">
                  <div className="inner-wrapper">
                     <div className="logo-box" >
                      
                     <img style={{height:'50px'}}  src={require('../../assets/images/home/logo-slider/click_here.png')} className="img-responsive" alt="Image" />
                     </div>
                  </div>
                  </Link> */}
            {/* 
                        </div>
                     </div>


                  </div>
               </div>
            </div> */}



         </section>

      </>
   );
};

export default    withTranslation()(LogoSlider);